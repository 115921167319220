import React from "react";
const LandingPage = React.lazy(() => import("containers/Landing/LandingPage"));

const AccountPage = React.lazy(() => import("containers/Account"));
const LabPage = React.lazy(() => import("containers/Lab/Lab"));
const SettingsPage = React.lazy(() => import("containers/Settings"));
const NotificationsPage = React.lazy(
  () => import("containers/Notifications/NotificationsPage")
);
const UsersPage = React.lazy(
  () => import("containers/Account/Users/UsersPage")
);
const ProjectPage = React.lazy(() => import("containers/Project"));
const PaymentPage = React.lazy(() => import("containers/Account/Payment"));
const PlanDetailPage = React.lazy(() => import("containers/Account/Plan"));
const MapPage = React.lazy(() => import("containers/Map"));
const ProjectsPage = React.lazy(() => import("containers/Projects"));
const CreateProjectsPage = React.lazy(() => import("containers/CreateProject"));
const SignUpMultiple = React.lazy(
  () => import("containers/Membership/SignUp/SignUpMultiple")
);

const SignInPage = React.lazy(
  () => import("containers/Membership/SignIn/SignIn")
);
const ResetPasswordPage = React.lazy(
  () => import("containers/Membership/ResetPassword/ResetPassword")
);
const ForgetPasswordPage = React.lazy(
  () => import("containers/Membership/ForgetPassword/ForgetPassword")
);
const SignUpPage = React.lazy(
  () => import("containers/Membership/SignUp/SignUp")
);
const NotFoundPage = React.lazy(() => import("containers/NotFound"));
const ReportPage = React.lazy(() => import("containers/Report"));

const routes = [
  { path: "/", Page: LandingPage, security: "public", layout: false },
  { path: "/sign-in", Page: SignInPage, security: "public", layout: false },
  { path: "/sign-up", Page: SignUpPage, security: "public", layout: false },
  {
    path: "/sign-up-multiple",
    Page: SignUpMultiple,
    security: "public",
    layout: false,
  },
  {
    path: "/forget_password",
    Page: ForgetPasswordPage,
    security: "public",
    layout: false,
  },
  {
    path: "/reset_password/:token",
    Page: ResetPasswordPage,
    security: "public",
    layout: false,
  },
  { path: "/map", Page: MapPage, security: "protected", layout: true },
  {
    path: "/project/create",
    Page: CreateProjectsPage,
    security: "protected",
    layout: true,
  },
  {
    path: "/projects",
    Page: ProjectsPage,
    security: "protected",
    layout: true,
  },
  { path: "/account", Page: AccountPage, security: "protected", layout: true },
  { path: "/project", Page: ProjectPage, security: "protected", layout: true },
  { path: "/project/recycle-bin", Page: ProjectPage, security: "protected" },
  {
    path: "/notifications",
    Page: NotificationsPage,
    security: "protected",
    layout: true,
  },
  { path: "/lab", Page: LabPage, security: "protected", layout: false },
  {
    path: "/lab/report",
    Page: ReportPage,
    security: "protected",
    layout: false,
  },
  {
    path: "/settings",
    Page: SettingsPage,
    security: "protected",
    layout: true,
  },
  {
    path: "/organization/users",
    Page: UsersPage,
    security: "protected",
    layout: true,
  },
  {
    path: "/organization/payment",
    Page: PaymentPage,
    security: "protected",
    layout: true,
  },
  {
    path: "/organization/plan-details",
    Page: PlanDetailPage,
    security: "protected",
    layout: true,
  },
  {
    path: "/*",
    Page: NotFoundPage,
    security: "public",
    layout: false,
  },
];

export default routes;
