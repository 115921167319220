import MapHeader from "./Header";
import MapKit from "components/mapKit/MapKit";
import classes from "./Layout.module.scss";
import { getProjects } from "services/webapp/projects/endpoints";
import { useQuery } from "@tanstack/react-query";
import useProjectStore from "context/project-store";
import { useEffect } from "react";

const MapLayout: React.FunctionComponent<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { setProjectCount } = useProjectStore();
  const { data } = useQuery({
    queryKey: ["projects_map"],
    queryFn: () => getProjects({}),
  });

  const projects = data?.data?.results;
  const metaData = data?.data?.metadata;
  useEffect(() => {
    setProjectCount(metaData?.count || 0);
  }, [projects, setProjectCount, metaData]);

  return (
    <div className={classes.layout}>
      <MapHeader />
      {children}
      <MapKit projects={projects || []} />
    </div>
  );
};

export default MapLayout;
